import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import VideoList from './components/VideoList';
import Popup from './components/Popup';
import { shuffleArray } from './utils';
import Upload from './tab/Upload';
import Tutorial from './tab/Tutorial';
import uploadIcon from './assets/upload.png';

const App = () => {
  const [videos, setVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [popupData, setPopupData] = useState({ title: '', link1: '', link2: '', link3: '', url: '' });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => callback();
      script.onerror = () => console.error('Error loading script.');
      document.body.appendChild(script);
    };

    loadScript('https://heoxml.netlify.app/xml.js', () => {
      if (window.videoData) {
        const shuffledData = shuffleArray(window.videoData);
        setVideos(shuffledData);
        setAllVideos(shuffledData);
      } else {
        console.error('No video data found.');
      }
    });
  }, []);

  const filterVideos = useCallback((query) => {
    if (query === '') {
      setVideos(allVideos);
    } else {
      setVideos(allVideos.filter(video => {
        const title = video.title ? video.title.toLowerCase() : '';
        const tags = video.tags ? video.tags.toLowerCase() : '';
        const videoId = video.url ? video.url.split('/').pop().split('?')[0] : '';

        return title.includes(query) || tags.includes(query) || videoId.includes(query);
      }));
    }
  }, [allVideos]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setSearchQuery(hash);
      filterVideos(hash);
    }
  }, [filterVideos]);

  const showPopup = (title, link1, link2, link3, jsonUrl) => {
    setPopupData({ title, link1, link2, link3, url: jsonUrl });
  };

  const closePopup = () => {
    setPopupData({ title: '', link1: '', link2: '', link3: '', url: '' });
  };

  const toggleMenu = () => {
    document.querySelector('.nav-links').classList.toggle('active');
  };

  const onSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterVideos(query);
  };

  return (
    <Router>
      <Navbar toggleMenu={toggleMenu} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header onSearch={onSearch} hideSearch={false} searchQuery={searchQuery} />
              <VideoList videos={videos} showPopup={showPopup} />
            </>
          }
        />
        <Route path="/upload" element={<Upload />} />
        <Route path="/tutorial" element={<Tutorial />} />
      </Routes>
      <Popup {...popupData} closePopup={closePopup} />
      <Link to="/upload" className="upload-button">
        <img src={uploadIcon} alt="Upload" />
      </Link>
    </Router>
  );
};

export default App;
