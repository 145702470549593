
import React from 'react';

const Upload = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>Belum ada apa-apa</h1>
    </div>
  );
  

export default Upload;
