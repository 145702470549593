import React, { useEffect, useState, useRef } from 'react';

const VideoList = ({ videos, showPopup }) => {
  const [shuffledVideos, setShuffledVideos] = useState([]);
  const videoRefs = useRef([]); // Simpan referensi untuk setiap iframe video

  useEffect(() => {
    const shuffleArray = (array) => {
      let shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    };

    setShuffledVideos(shuffleArray(videos));

    const existingScript = document.getElementById('tiktok-embed-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'tiktok-embed-script';
      script.src = "https://www.tiktok.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const iframe = entry.target;
          if (!entry.isIntersecting && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
              { type: "pause", "x-tiktok-player": true },
              '*'
            );
          }
        });
      },
      { threshold: 0.1 }
    );

    videoRefs.current.forEach((iframe) => {
      if (iframe) observer.observe(iframe);
    });

    return () => observer.disconnect();

  }, [videos]);

  return (
    <div className="video-container">
      {shuffledVideos.map((video, index) => {
        const urlParts = video.url.split('/');
        const videoId = urlParts[urlParts.length - 1]?.split('?')[0];

        return (
          <div className="video-item" key={index} data-id={videoId}>
            <iframe
              className="tiktok-iframe"
              ref={(el) => (videoRefs.current[index] = el)}
              src={`https://www.tiktok.com/player/v1/${videoId}?autoplay=0`}
              title={`TikTok Video ${videoId}`}
              loading="lazy"
              allow="fullscreen"
            ></iframe>

            <button
              className="download-btn"
              onClick={() =>
                showPopup(video.title, video.link1, video.link2, video.link3, video.url) // Kirim videoId ke showPopup
              }
            >
              Open Preset
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default VideoList;
