import React, { useState, useEffect } from 'react';
import './css/Navbar.css';

const Header = ({ onSearch, hideSearch, searchQuery }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`header ${isScrolled ? 'fixed' : ''}`}>
      {!hideSearch && (
        <input 
          type="text" 
          id="searchInput" 
          placeholder="Search XML or Enter Id" 
          onInput={onSearch} 
          value={searchQuery}
          className="search-box"
        />
      )}
    </header>
  );
};

export default Header;
