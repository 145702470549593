import React from 'react';
import { DiscussionEmbed } from 'disqus-react';

const Popup = ({ title, link1, link2, link3, url, videoId, closePopup }) => {

  const showLink1 = link1 && link1.trim() !== '';
  const showLink2 = link2 && link2.trim() !== '';
  const showLink3 = link3 && link3.trim() !== '';
  
  const finalLink3 = showLink3 ? link3 : url;

  // Konfigurasi Disqus
  const disqusShortname = "heo-my-id"; // Ganti dengan shortname Disqus kamu
  const disqusConfig = {
    url: `${url}`, // Gunakan URL video sebagai identitas unik
    identifier: videoId, // Gunakan videoId sebagai identifier yang unik
    title: `Komentar untuk ${title}`, 
    language: 'id',
  };

  return (
    <div 
      id="popup" 
      className="popup" 
      style={{ display: title ? 'flex' : 'none' }} 
      onClick={closePopup} 
      aria-label="Popup window"
    >
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <button className="popup-close" onClick={closePopup} aria-label="Close popup">&times;</button>
        <h3>{title}</h3>
        {showLink1 && <a id="link1" href={link1} target="_blank" rel="noreferrer">XML File</a>}
        {showLink2 && <a id="link2" href={link2} target="_blank" rel="noreferrer">Preset -5MB</a>}
        {finalLink3 && (
          <a id="link3" href={finalLink3} target="_blank" rel="noreferrer">
            {showLink3 ? 'Sound' : 'Sound Buka TT'}
          </a>
        )}

        {/* Tambahkan Disqus di sini */}
        <div className="disqus-container">
          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </div>
    </div>
  );
};

export default Popup;
